@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-section-bg: #ffffff;
    --color-card-bg: #ffffff;
    --color-text-base: #333333;
    --color-text-emphasized: #000000;

    /* --color-text-base-alt: #333333; */
    --color-text-base-alt: 51, 51, 51; /* rgb for #333333 */
    --color-text-brand-emphasized: #333333;
    --color-border-base: #e2e2e2;

    --color-price-base: #e08700;
    --color-course-base: #666666;
    --color-date-base: #333333;
    --color-hr-base: #e0e0e0;
    --color-btn-base: #2c2c2c;
  }

  .theme-dark {
    --color-section-bg: #2c2c2c;
    --color-card-bg: #444444;
    --color-text-base: #aaaaaa;
    --color-text-emphasized: #f2f2f2;
    /* --color-text-base-alt: #f2f2f2; */
    --color-text-base-alt: 242, 424, 424; /* rgb for #f2f2f2; */
    --color-text-brand-emphasized: #fbc91b;
    --color-border-base: #444;

    --color-price-base: #f2f2f2;
    --color-course-base: #aaaaaa;
    --color-date-base: #f2f2f2;
    --color-hr-base: #444444;
    --color-btn-base: #f2f2f2;
  }
}

@layer components {
  .carousel-nav-btn-shadow {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
  }
}

@layer utilities {
  .scroll-snap-none {
    scroll-snap-type: none;
  }

  .scroll-snap-x {
    -ms-scroll-snap-type: x;
    scroll-snap-type: x;
  }

  .scroll-snap-x-mandatory {
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
  }

  .scroll-snap-y {
    -ms-scroll-snap-type: y;
    scroll-snap-type: y;
  }

  .scroll-smooth {
    scroll-behavior: smooth;
  }

  .scroll-snap-center {
    scroll-snap-align: center;
  }

  .scroll-snap-start {
    scroll-snap-align: start;
  }

  .max-w-848 {
    max-width: 848px;
  }

  .h-full-dvh {
    height: 100vh; /* fallback for browsers that don't support 100dvh */
    height: 100dvh; /* fallback for browsers that don't support dvh */
  }

  .text-wrap {
    text-wrap: wrap;
  }

  .desktop-only {
    @apply hidden xl:block;
  }

  .mobile-only {
    @apply block xl:hidden;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .small-scrollbar::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  .small-scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
  }

  .small-scrollbar::-webkit-scrollbar-thumb {
    background: #666666;
    border-radius: 100vh;
    border: 3px solid #666666;
  }

  .small-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #444444;
  }
}

.section-title {
  @apply mx-0 my-12 font-poppins text-14 font-medium uppercase text-orange-default xl:text-18;
}

.title-hero {
  @apply text-48 font-bold;
}

/* .section-title {
  @apply font-medium font-poppins text-orange-default text-14 xl:text-18;
} */

.common-style {
  @apply font-poppins text-14 xl:text-48;

  font-size: 48px;
}

.page-section-padding {
  @apply px-16 py-24 xl:px-80 xl:py-48;
}

.react-datepicker {
  border: none !important;
}

.container-timePicker_date .react-datepicker__input-container input {
  padding: 10px 12px;
  border: 1px solid #dbdada;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  text-transform: lowercase;
  width: 110px;
}

/* .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #1a202c !important;
  border-radius: 50% !important;
} */

.react-datepicker-popper {
  @apply z-over-intercom !important;
}

.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle {
  display: none;
}

.react-datepicker__month-container {
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  position: absolute !important;
  top: 19px;
  left: -107px;
  padding-right: 25px;
  width: 202px !important;
}

.container-timePicker_date
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100% !important;
  text-align: left !important;
  margin: 0 !important;
  text-transform: lowercase;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 40px !important;
  display: flex;
  align-items: center;
  padding: 5px 15px !important;
}

.container-timePicker_date
  .react-datepicker__header:not(
    .react-datepicker__header--has-time-select
  ) {
  display: none;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle {
  display: none;
}

.react-datepicker__time-container .react-datepicker__time {
  box-shadow: 0px 3px 28px rgb(0 0 0 / 8%);
  border-radius: 4px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #f0f0f0 !important;
  color: #000 !important;
  font-weight: normal !important;
}

/* .react-datepicker__month-container {
  height: 330px;
} */

/* .react-datepicker__day--selected {
  background-color: #1a202c !important;
  border-radius: 50% !important;
} */

.react-datepicker__day:hover {
  background-color: #edf2f7 !important;
  border-radius: 50% !important;
}

/* .react-datepicker__navigation--previous {
  left: auto !important;
  right: 40px !important;
} */

/* .react-datepicker__current-month {
  font-weight: 500 !important;
  width: 60%;
} */

.footer-icon {
  margin: auto;
}

.footer-icon path {
  fill: #aaaaaa;
  fill-opacity: 1;
}

/*Remove the number change buttons in number input field*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

@keyframes loading {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.embla__dot--selected .loading {
  animation: loading 8s ease-in-out;
}

body {
  color: #1b1b18;
  @apply font-poppins;
  scrollbar-gutter: stable;
}

/*This is used for the editor's variable autocompletion*/
#typeahead-menu {
  @apply z-over-intercom;
}

// Temp fix, bg-gradient-to-* not working anymore from tailwind
.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.min-w-fit {
  min-width: fit-content;
}

#shaka-player-ui-time-container {
  display: block;
  padding: 0 0.5rem !important;
  border: none !important;
  font-size: 0.875rem;
  color: #1b1b18 !important;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #ffce00;

  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

.react-datepicker-wrapper {
  width: 100%; // Need to specify width as it is missing on prod build
}

#document-body {
  .react-datepicker {
    @apply font-poppins;
    border: none !important;
    border-radius: 12px;
    overflow: hidden;
    display: block;
  }

  .react-datepicker__navigation-icon::before {
    border-color: #333333;
    border-width: 2px 2px 0 0;
  }

  .react-datepicker__header {
    border-bottom: 0;
    padding-top: 11px;
    padding-bottom: 16px;
    background-color: white;
  }
  .react-datepicker__current-month {
    line-height: 20px;
    font-weight: 400;
    padding: 24px;
    padding-top: 16px;
    padding-bottom: 20px;
  }

  .react-datepicker__month {
    padding: 0 12px 16px 12px;
    margin: 0;
  }

  .react-datepicker__month-text {
    width: 64px;
    height: 64px;
    padding: 8px;
    color: #1b1b18;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &--disabled {
      color: var(--npl-neutral-light-solid-08, #c8c7c1);
    }
  }

  .react-datepicker__month--disabled {
    color: var(--npl-neutral-light-solid-08, #c8c7c1);
  }

  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__month--selected {
    border-radius: 8px;
    border: 1px solid var(--npl-yellow-light-solid-06, #fae39e) !important;
    background: var(--npl-yellow-light-solid-03, #fff9e5) !important;
  }

  .react-datepicker__month-text--keyboard-selected {
    color: #34280f;
    font-weight: 500;
  }

  .react-datepicker-year-header {
    font-weight: 600;
  }

  .react-datepicker__navigation {
    top: 18px;
    width: 40px;
    height: 40px;

    &-icon {
      top: 4px;
      &--next,
      &--previous {
        left: 0px;
      }
    }

    &--previous {
      left: 24px;
      border-radius: 1000px;
      &:hover {
        background: #f3f3f2;
      }
    }
    &--next {
      right: 24px;
      border-radius: 1000px;
      &:hover {
        background: #f3f3f2;
      }
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #1b1b18;
    margin: 2px 0px;
    font-size: 14px;
  }

  .react-datepicker__day {
    position: relative;

    &:hover {
      background-color: #f3f3f2 !important;
    }

    &:active {
      background-color: #e9e9e7 !important;
    }

    &--disabled {
      color: rgba(27, 27, 24, 0.65) !important;
      pointer-events: none;

      &:hover {
        background: transparent !important;
      }
    }
  }

  .react-datepicker__week {
    display: flex;
  }

  .react-datepicker__day-name {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #1b1b18;
  }

  .react-datepicker__day-names {
    margin-bottom: 0;
  }

  .react-datepicker__day--keyboard-selected {
    background: #fccb1d !important;
    border-radius: 1000px;
    outline: none;
  }

  .react-datepicker__day--selected {
    background-color: #fccb1d !important;
    border-radius: 50%;
    color: #1b1b18 !important;

    &:hover {
      background-color: #fccb1d !important;
    }
  }

  .react-datepicker__day--today {
    color: #1b1b18;
    font-weight: 400;
  }

  .react-datepicker__day--outside-month {
    color: rgba(27, 27, 24, 0.65);
    pointer-events: none;

    &:hover {
      background: transparent !important;
    }
  }

  .react-datepicker__month-container {
    box-shadow: none;
    width: 100%;
  }
}
