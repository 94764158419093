@import '../../../styles/all.scss';

@keyframes modal-slide-up {
  from {
    bottom: -50%;
    opacity: 0;
  }
  to {
    bottom: 0%;
    opacity: 1;
  }
}

@keyframes modal-slide-down {
  from {
    bottom: 0%;
    opacity: 1;
  }
  to {
    bottom: -50%;
    opacity: 0;
  }
}

.c-PopOverModal {
  display: none;

  &--open {
    display: block;
  }

  &__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &__container {
    position: fixed;
    width: 100%;
    bottom: 0%;

    background-color: white;
    box-sizing: border-box;
    animation: modal-slide-up 0.3s ease-out;

    border-radius: 28px 28px 0px 0px;
    box-shadow:
      0px 1px 4px 0px rgba(27, 27, 24, 0.1),
      0px 4px 6px 0px rgba(27, 27, 24, 0.04),
      0px 8px 16px 0px rgba(27, 27, 24, 0.04),
      0px 10px 20px 2px rgba(27, 27, 24, 0.02),
      0px 12px 24px 4px rgba(27, 27, 24, 0.04);

    &__no-gutter {
      padding: 0;
    }
  }

  &__inner {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 32px;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;

    @include for-desktop {
      padding: 32px;
    }

    &__no-gutter {
      padding: 0;
    }
  }
  &__eyebrow {
    align-self: flex-start;
    border-radius: 28px;
    width: 100%;
  }

  &__chin {
    align-self: flex-end;
    width: 100%;
    border-radius: 28px;
  }

  &__transparent_white_color {
    background: transparent;
    width: 90%;
  }

  &__left-icon {
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 8px;
  }

  &__close-modal {
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    right: 16px;
    top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    transition: all 0.2s ease-in-out;
    z-index: 2;
  }

  &__outside-close-modal {
    top: 24px;
    right: 24px;
  }

  /* Tooltip text */
  &__tooltip &__tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    // Position Right
    top: -5px;
    left: 105%;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1001;
  }
  &__tooltip:hover &__tooltiptext {
    visibility: visible;
  }
  &--animated {
    animation: 0.3s ease-in-out;
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
